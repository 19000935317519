export const Icons = {
	Warning: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			width={24}
			height={24}
			strokeWidth={1.5}
			stroke="currentColor"
			{...props}>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
			/>
		</svg>
	),
	ViewFinder: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth="1.5"
			width={24}
			height={24}
			stroke="currentColor"
			{...props}>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M7.5 3.75H6A2.25 2.25 0 0 0 3.75 6v1.5M16.5 3.75H18A2.25 2.25 0 0 1 20.25 6v1.5m0 9V18A2.25 2.25 0 0 1 18 20.25h-1.5m-9 0H6A2.25 2.25 0 0 1 3.75 18v-1.5M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
			/>
		</svg>
	),
	ChatBubbles: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			width={24}
			height={24}
			strokeWidth="1.5"
			stroke="currentColor"
			{...props}>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
			/>
		</svg>
	),
	Leave: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth="1.5"
			stroke="currentColor"
			width={24}
			height={24}
			{...props}>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15"
			/>
		</svg>
	),
	Loading: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={40}
			height={30}
			viewBox="0 0 200 200"
			{...props}>
			<circle
				fill="currentColor"
				stroke="currentColor"
				strokeWidth="13"
				r="15"
				cx="40"
				cy="100">
				<animate
					attributeName="opacity"
					calcMode="spline"
					dur="2"
					values="1;0;1;"
					keySplines=".5 0 .5 1;.5 0 .5 1"
					repeatCount="indefinite"
					begin="-.4"></animate>
			</circle>
			<circle
				fill="currentColor"
				stroke="currentColor"
				strokeWidth="13"
				r="15"
				cx="100"
				cy="100">
				<animate
					attributeName="opacity"
					calcMode="spline"
					dur="2"
					values="1;0;1;"
					keySplines=".5 0 .5 1;.5 0 .5 1"
					repeatCount="indefinite"
					begin="-.2"></animate>
			</circle>
			<circle
				fill="currentColor"
				stroke="currentColor"
				strokeWidth="13"
				r="15"
				cx="160"
				cy="100">
				<animate
					attributeName="opacity"
					calcMode="spline"
					dur="2"
					values="1;0;1;"
					keySplines=".5 0 .5 1;.5 0 .5 1"
					repeatCount="indefinite"
					begin="0"></animate>
			</circle>
		</svg>
	),
	PauseCircle: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			style={{ transform: "translateX(-1px)" }}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M3.61304 16.3225C3.61304 8.96253 9.58637 2.9892 16.9464 2.9892C24.3197 2.9892 30.2797 8.96253 30.2797 16.3225C30.2797 23.6825 24.3064 29.6559 16.9464 29.6559C9.58637 29.6559 3.61304 23.6825 3.61304 16.3225ZM11.1294 13.8218C11.1294 12.2969 12.3657 11.0606 13.8906 11.0606C15.4155 11.0606 16.6519 12.2969 16.6519 13.8218V18.8232C16.6519 20.3483 15.4155 21.5844 13.8906 21.5844C12.3657 21.5844 11.1294 20.3483 11.1294 18.8232V13.8218ZM17.321 13.8218C17.321 12.2969 18.5574 11.0606 20.0823 11.0606C21.6072 11.0606 22.8435 12.2969 22.8435 13.8218V18.8232C22.8435 20.3483 21.6072 21.5844 20.0823 21.5844C18.5574 21.5844 17.321 20.3483 17.321 18.8232V13.8218Z"
				fill="#007BFF"
			/>
		</svg>
	),
	PlayCircle: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M15.96 2.66669C8.60004 2.66669 2.62671 8.64002 2.62671 16C2.62671 23.36 8.60004 29.3334 15.96 29.3334C23.32 29.3334 29.2934 23.36 29.2934 16C29.2934 8.64002 23.3334 2.66669 15.96 2.66669ZM19.96 18.9734L16.0934 21.2C15.6134 21.48 15.08 21.6134 14.56 21.6134C14.0267 21.6134 13.5067 21.48 13.0267 21.2C12.0667 20.64 11.4934 19.6534 11.4934 18.5334V14.0667C11.4934 12.96 12.0667 11.96 13.0267 11.4C13.9867 10.84 15.1334 10.84 16.1067 11.4L19.9734 13.6267C20.9334 14.1867 21.5067 15.1734 21.5067 16.2934C21.5067 17.4134 20.9334 18.4134 19.96 18.9734Z"
				fill="#007BFF"
			/>
		</svg>
	),
	Dislike: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M8.50695 17.7102L8.49597 17.7062L8.48485 17.7025C7.74065 17.4584 7.258 16.6169 7.4223 15.8658L7.42634 15.8473L7.42925 15.8286L7.83758 13.2036L7.83763 13.2036L7.83872 13.196C7.8554 13.0792 7.88061 12.7649 7.64155 12.4917L7.62795 12.4762L7.61336 12.4616C7.45176 12.3 7.23414 12.2133 7.00828 12.2133H3.67495C3.03106 12.2133 2.52502 11.9474 2.2402 11.543L2.23879 11.541C1.96191 11.1511 1.88551 10.6102 2.09096 10.0452L2.0943 10.036L2.09735 10.0267L4.08902 3.96004L4.09554 3.94018L4.1007 3.91994C4.33422 3.00478 5.36367 2.20331 6.43328 2.20331H9.59995C9.78251 2.20331 10.0985 2.23248 10.422 2.32023C10.7541 2.41031 11.025 2.54346 11.1865 2.70505L11.2146 2.7331L11.246 2.75735L13.28 4.32819L13.2723 4.33826L11.2046 2.73747C10.9996 2.54846 10.7228 2.42661 10.4826 2.35117C10.212 2.26622 9.90153 2.21331 9.59995 2.21331H6.43328C5.90063 2.21331 5.38473 2.42214 4.98319 2.71934C4.59098 3.00962 4.24479 3.43173 4.1128 3.9344L2.10545 10.0311C1.92845 10.525 1.92809 11.0837 2.25439 11.5362C2.58421 11.999 3.13321 12.2033 3.68328 12.2033H7.01662C7.27261 12.2033 7.50106 12.3105 7.65683 12.4898L7.65761 12.4907C7.8166 12.6731 7.8941 12.9218 7.85361 13.1988L7.43967 15.8563C7.28348 16.6371 7.78684 17.4456 8.50988 17.6929C8.87728 17.827 9.25828 17.7841 9.55226 17.6861C9.84148 17.5897 10.1531 17.4042 10.3442 17.1262L10.3442 17.1262L10.3479 17.1208L13.4174 12.5539L13.4241 12.5585L10.352 17.1291L10.3519 17.1294C10.0893 17.5205 9.55302 17.7966 8.99995 17.7966C8.82866 17.7966 8.66016 17.767 8.50695 17.7102Z"
				fill="#4F4F4F"
				stroke="#4F4F4F"
				strokeWidth="1.24"
			/>
			<path
				d="M16.3501 15.0833H15.5168C13.9751 15.0833 13.2251 14.3583 13.2251 12.875V4.70833C13.2251 3.225 13.9751 2.5 15.5168 2.5H16.3501C17.8918 2.5 18.6418 3.225 18.6418 4.70833V12.875C18.6418 14.3583 17.8918 15.0833 16.3501 15.0833ZM15.5168 3.75C14.6084 3.75 14.4751 3.96667 14.4751 4.70833V12.875C14.4751 13.6167 14.6084 13.8333 15.5168 13.8333H16.3501C17.2584 13.8333 17.3918 13.6167 17.3918 12.875V4.70833C17.3918 3.96667 17.2584 3.75 16.3501 3.75H15.5168Z"
				fill="#4F4F4F"
			/>
		</svg>
	),
	Like: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M8.81329 17.2949L8.78524 17.2668L8.75385 17.2426L6.7198 15.6718L6.72759 15.6617L8.79934 17.2656C9.00337 17.4485 9.27929 17.5679 9.51987 17.6423C9.78866 17.7254 10.0983 17.7783 10.3999 17.7783H13.5666C14.0992 17.7783 14.6151 17.5695 15.0166 17.2723C15.4089 16.982 15.755 16.5599 15.887 16.0572L17.8944 9.96051C18.0714 9.46658 18.0717 8.90798 17.7455 8.4554C17.4156 7.99262 16.8666 7.78831 16.3166 7.78831H12.9832C12.7272 7.78831 12.4988 7.68115 12.343 7.50178L12.3422 7.50088C12.1832 7.31851 12.1057 7.06986 12.1462 6.79284L12.5602 4.13534C12.7165 3.35381 12.2121 2.54459 11.4881 2.29809C11.1196 2.1656 10.7399 2.20654 10.4436 2.30584C10.1529 2.4032 9.84535 2.58945 9.65565 2.86539L9.65562 2.86537L9.65198 2.87078L6.58051 7.44053L6.57764 7.43861L9.64779 2.87083L9.64825 2.87015C10.0131 2.32572 10.8588 2.049 11.4882 2.28794L11.5015 2.29299L11.515 2.29742C12.2592 2.54152 12.7418 3.38307 12.5775 4.13415L12.5735 4.15264L12.5706 4.17134L12.1623 6.79634L12.1622 6.79633L12.1611 6.80396C12.1444 6.92074 12.1192 7.23504 12.3583 7.50824L12.3719 7.52378L12.3865 7.53838C12.5481 7.69997 12.7657 7.78664 12.9916 7.78664H16.3249C16.9688 7.78664 17.4748 8.0525 17.7596 8.45696L17.7611 8.45895C18.0379 8.84885 18.1143 9.38978 17.9089 9.95476L17.9055 9.96395L17.9025 9.97325L15.9108 16.0399L15.9043 16.0598L15.8991 16.08C15.6654 16.9961 14.6435 17.7966 13.5666 17.7966H10.3999C10.2173 17.7966 9.90135 17.7675 9.57781 17.6797C9.24573 17.5896 8.97488 17.4565 8.81329 17.2949Z"
				fill="#4F4F4F"
				stroke="#4F4F4F"
				strokeWidth="1.24"
			/>
			<path
				d="M4.4834 17.5H3.65007C2.1084 17.5 1.3584 16.775 1.3584 15.2917V7.12502C1.3584 5.64169 2.1084 4.91669 3.65007 4.91669H4.4834C6.02507 4.91669 6.77507 5.64169 6.77507 7.12502V15.2917C6.77507 16.775 6.02507 17.5 4.4834 17.5ZM3.65007 6.16669C2.74173 6.16669 2.6084 6.38335 2.6084 7.12502V15.2917C2.6084 16.0334 2.74173 16.25 3.65007 16.25H4.4834C5.39173 16.25 5.52507 16.0334 5.52507 15.2917V7.12502C5.52507 6.38335 5.39173 6.16669 4.4834 6.16669H3.65007Z"
				fill="#4F4F4F"
			/>
		</svg>
	),
	Refresh: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M18.3334 10C18.3334 14.6 14.6001 18.3334 10.0001 18.3334C5.40008 18.3334 2.59175 13.7 2.59175 13.7M2.59175 13.7H6.35842M2.59175 13.7V17.8667M1.66675 10C1.66675 5.40002 5.36675 1.66669 10.0001 1.66669C15.5584 1.66669 18.3334 6.30002 18.3334 6.30002M18.3334 6.30002V2.13335M18.3334 6.30002H14.6334"
				stroke="#4F4F4F"
				strokeWidth="1.24"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	Volume: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M11.5666 17.1583C10.9082 17.1583 10.1832 16.925 9.45824 16.4667L7.0249 14.9417C6.85824 14.8417 6.66657 14.7833 6.4749 14.7833H5.2749C3.25824 14.7833 2.1499 13.675 2.1499 11.6583V8.32501C2.1499 6.30834 3.25824 5.20001 5.2749 5.20001H6.46657C6.65824 5.20001 6.8499 5.14167 7.01657 5.04167L9.4499 3.51667C10.6666 2.75834 11.8499 2.61667 12.7832 3.13334C13.7166 3.65001 14.2249 4.72501 14.2249 6.16667V13.8083C14.2249 15.2417 13.7082 16.325 12.7832 16.8417C12.4166 17.0583 12.0082 17.1583 11.5666 17.1583ZM5.2749 6.45834C3.95824 6.45834 3.3999 7.01667 3.3999 8.33334V11.6667C3.3999 12.9833 3.95824 13.5417 5.2749 13.5417H6.46657C6.8999 13.5417 7.31657 13.6583 7.68324 13.8917L10.1166 15.4167C10.9249 15.9167 11.6749 16.05 12.1832 15.7667C12.6916 15.4833 12.9832 14.775 12.9832 13.8333V6.17501C12.9832 5.22501 12.6916 4.51667 12.1832 4.24167C11.6749 3.95834 10.9249 4.08334 10.1166 4.59167L7.68324 6.10834C7.31657 6.34167 6.8999 6.45834 6.46657 6.45834H5.2749Z"
				fill="#4F4F4F"
			/>
			<path
				d="M16.1084 13.9583C15.975 13.9583 15.85 13.9167 15.7334 13.8333C15.4584 13.625 15.4 13.2333 15.6084 12.9583C16.9167 11.2167 16.9167 8.78334 15.6084 7.04167C15.4 6.76667 15.4584 6.375 15.7334 6.16667C16.0084 5.95834 16.4 6.01667 16.6084 6.29167C18.25 8.475 18.25 11.525 16.6084 13.7083C16.4917 13.875 16.3 13.9583 16.1084 13.9583Z"
				fill="#4F4F4F"
			/>
		</svg>
	),
	Copy: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M9.25008 18.9584H5.75008C2.49175 18.9584 1.04175 17.5084 1.04175 14.25V10.75C1.04175 7.49169 2.49175 6.04169 5.75008 6.04169H9.25008C12.5084 6.04169 13.9584 7.49169 13.9584 10.75V14.25C13.9584 17.5084 12.5084 18.9584 9.25008 18.9584ZM5.75008 7.29169C3.16675 7.29169 2.29175 8.16669 2.29175 10.75V14.25C2.29175 16.8334 3.16675 17.7084 5.75008 17.7084H9.25008C11.8334 17.7084 12.7084 16.8334 12.7084 14.25V10.75C12.7084 8.16669 11.8334 7.29169 9.25008 7.29169H5.75008V7.29169Z"
				fill="#4F4F4F"
			/>
			<path
				d="M14.2501 13.9584H13.3334C12.9917 13.9584 12.7084 13.675 12.7084 13.3334V10.75C12.7084 8.16669 11.8334 7.29169 9.25008 7.29169H6.66675C6.32508 7.29169 6.04175 7.00835 6.04175 6.66669V5.75002C6.04175 2.49169 7.49175 1.04169 10.7501 1.04169H14.2501C17.5084 1.04169 18.9584 2.49169 18.9584 5.75002V9.25002C18.9584 12.5084 17.5084 13.9584 14.2501 13.9584ZM13.9584 12.7084H14.2501C16.8334 12.7084 17.7084 11.8334 17.7084 9.25002V5.75002C17.7084 3.16669 16.8334 2.29169 14.2501 2.29169H10.7501C8.16675 2.29169 7.29175 3.16669 7.29175 5.75002V6.04169H9.25008C12.5084 6.04169 13.9584 7.49169 13.9584 10.75V12.7084Z"
				fill="#4F4F4F"
			/>
		</svg>
	),
	Logo: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M12 4.75001C11.9 4.75001 11.8 4.74001 11.71 4.72001C11.61 4.70001 11.52 4.67001 11.43 4.64001C11.34 4.60001 11.25 4.55001 11.17 4.50001C11.09 4.44001 11.01 4.38 10.94 4.31C10.66 4.03 10.5 3.65001 10.5 3.25001C10.5 2.85001 10.66 2.47001 10.94 2.19001C11.01 2.12001 11.09 2.06001 11.17 2.00001C11.25 1.95001 11.34 1.9 11.43 1.87C11.52 1.83 11.61 1.8 11.71 1.78C12.19 1.68 12.71 1.84001 13.06 2.19001C13.34 2.47001 13.5 2.85001 13.5 3.25001C13.5 3.65001 13.34 4.03 13.06 4.31C12.78 4.59 12.39 4.75001 12 4.75001Z"
				fill="#4F4F4F"
			/>
			<path
				d="M12 9.25C11.9 9.25 11.8 9.24 11.71 9.22C11.61 9.2 11.52 9.17 11.43 9.14C11.34 9.1 11.25 9.05 11.17 9C11.09 8.94 11.01 8.88 10.94 8.81C10.66 8.53 10.5 8.15 10.5 7.75C10.5 7.35 10.66 6.97 10.94 6.69C11.01 6.62 11.09 6.56 11.17 6.5C11.25 6.45 11.34 6.4 11.43 6.37C11.52 6.33 11.61 6.3 11.71 6.28C11.9 6.24 12.1 6.24 12.29 6.28C12.39 6.3 12.48 6.33 12.57 6.37C12.66 6.4 12.75 6.45 12.83 6.5C12.91 6.56 12.99 6.62 13.06 6.69C13.34 6.97 13.5 7.35 13.5 7.75C13.5 8.15 13.34 8.53 13.06 8.81C12.99 8.88 12.91 8.94 12.83 9C12.75 9.05 12.66 9.1 12.57 9.14C12.48 9.17 12.39 9.2 12.29 9.22C12.2 9.24 12.1 9.25 12 9.25Z"
				fill="#4F4F4F"
			/>
			<path
				d="M12 13.25C11.9 13.25 11.8 13.24 11.71 13.22C11.61 13.2 11.52 13.17 11.43 13.14C11.34 13.1 11.25 13.05 11.17 13C11.09 12.94 11.01 12.88 10.94 12.81C10.87 12.74 10.81 12.67 10.75 12.58C10.7 12.5 10.65 12.41 10.61 12.32C10.58 12.23 10.55 12.14 10.53 12.04C10.51 11.95 10.5 11.85 10.5 11.75C10.5 11.35 10.66 10.97 10.94 10.69C11.01 10.62 11.09 10.56 11.17 10.5C11.25 10.45 11.34 10.4 11.43 10.36C11.52 10.33 11.61 10.3 11.71 10.28C12.19 10.18 12.71 10.34 13.06 10.69C13.34 10.97 13.5 11.35 13.5 11.75C13.5 11.85 13.49 11.95 13.47 12.04C13.45 12.14 13.42 12.23 13.38 12.32C13.35 12.41 13.3 12.5 13.25 12.58C13.19 12.67 13.13 12.74 13.06 12.81C12.99 12.88 12.91 12.94 12.83 13C12.75 13.05 12.66 13.1 12.57 13.14C12.48 13.17 12.39 13.2 12.29 13.22C12.2 13.24 12.1 13.25 12 13.25Z"
				fill="#4F4F4F"
			/>
			<path
				d="M12 16.75C11.74 16.75 11.48 16.64 11.29 16.46C11.11 16.27 11 16.01 11 15.75C11 15.49 11.11 15.23 11.29 15.04C11.52 14.81 11.87 14.71 12.19 14.77C12.26 14.78 12.32 14.8 12.38 14.83C12.44 14.85 12.5 14.88 12.55 14.92C12.61 14.96 12.66 15 12.71 15.04C12.89 15.23 13 15.49 13 15.75C13 16.01 12.89 16.27 12.71 16.46C12.66 16.5 12.61 16.54 12.55 16.58C12.5 16.62 12.44 16.65 12.38 16.67C12.32 16.7 12.26 16.72 12.19 16.73C12.13 16.74 12.06 16.75 12 16.75Z"
				fill="#4F4F4F"
			/>
			<path
				d="M12 20C11.9 20 11.8 19.98 11.71 19.94C11.62 19.9 11.54 19.85 11.47 19.78C11.4 19.71 11.34 19.63 11.31 19.54C11.27 19.45 11.25 19.35 11.25 19.25C11.25 19.05 11.33 18.86 11.47 18.72C11.54 18.65 11.62 18.6 11.71 18.56C11.9 18.48 12.1 18.48 12.29 18.56C12.38 18.6 12.46 18.65 12.53 18.72C12.6 18.79 12.65 18.87 12.69 18.96C12.73 19.05 12.75 19.15 12.75 19.25C12.75 19.35 12.73 19.45 12.69 19.54C12.65 19.63 12.6 19.71 12.53 19.78C12.46 19.85 12.38 19.9 12.29 19.94C12.19 19.98 12.1 20 12 20Z"
				fill="#4F4F4F"
			/>
			<path
				d="M12 22.07C11.87 22.07 11.74 22.02 11.65 21.92C11.55 21.83 11.5 21.7 11.5 21.57C11.5 21.44 11.55 21.31 11.65 21.22C11.83 21.03 12.17 21.03 12.35 21.22C12.45 21.31 12.5 21.44 12.5 21.57C12.5 21.7 12.45 21.83 12.35 21.92C12.26 22.02 12.13 22.07 12 22.07Z"
				fill="#4F4F4F"
			/>
			<path
				d="M3.5 9.25C3.1 9.25 2.72 9.09 2.44 8.81C2.16 8.53 2 8.15 2 7.75C2 7.36 2.16 6.97 2.44 6.69C2.99 6.13 4 6.13 4.56 6.69C4.84 6.97 5 7.36 5 7.75C5 8.15 4.84 8.53 4.56 8.81C4.49 8.88 4.41002 8.94 4.33002 9C4.25002 9.05 4.16001 9.10001 4.07001 9.13001C3.98001 9.17001 3.88998 9.2 3.78998 9.22C3.69998 9.24 3.6 9.25 3.5 9.25Z"
				fill="#4F4F4F"
			/>
			<path
				d="M20.5 9.25C20.11 9.25 19.72 9.09 19.44 8.81C19.16 8.53 19 8.14 19 7.75C19 7.65 19.01 7.55001 19.03 7.46001C19.05 7.36001 19.08 7.26999 19.11 7.17999C19.15 7.08999 19.2 7 19.25 6.92C19.31 6.84 19.37 6.76 19.44 6.69C19.51 6.62 19.59 6.56 19.67 6.5C19.75 6.45 19.84 6.4 19.93 6.37C20.02 6.33 20.11 6.3 20.21 6.28C20.4 6.24 20.6 6.24 20.79 6.28C20.89 6.3 20.98 6.33 21.07 6.37C21.16 6.4 21.25 6.45 21.33 6.5C21.41 6.56 21.49 6.62 21.56 6.69C21.63 6.76 21.69 6.84 21.75 6.92C21.8 7 21.85 7.08999 21.88 7.17999C21.92 7.26999 21.95 7.36001 21.97 7.46001C21.99 7.55001 22 7.65 22 7.75C22 8.15 21.84 8.53 21.56 8.81C21.49 8.88 21.41 8.94 21.33 9C21.25 9.05 21.16 9.1 21.07 9.14C20.98 9.17 20.89 9.2 20.79 9.22C20.69 9.24 20.6 9.25 20.5 9.25Z"
				fill="#4F4F4F"
			/>
			<path
				d="M20.5 12.75C20.44 12.75 20.37 12.74 20.31 12.73C20.24 12.72 20.18 12.7 20.12 12.67C20.06 12.65 20 12.62 19.94 12.58C19.89 12.55 19.84 12.5 19.79 12.46C19.75 12.41 19.7 12.36 19.67 12.31C19.63 12.25 19.6 12.19 19.58 12.13C19.55 12.07 19.53 12.01 19.52 11.95C19.51 11.88 19.5 11.82 19.5 11.75C19.5 11.49 19.61 11.23 19.79 11.04C19.84 11 19.89 10.96 19.94 10.92C20 10.88 20.06 10.85 20.12 10.83C20.18 10.8 20.24 10.78 20.31 10.77C20.43 10.74 20.56 10.74 20.69 10.77C20.76 10.78 20.82 10.8 20.88 10.83C20.94 10.85 21 10.88 21.05 10.92C21.11 10.96 21.16 11 21.21 11.04C21.39 11.23 21.5 11.49 21.5 11.75C21.5 11.82 21.49 11.88 21.48 11.95C21.47 12.01 21.45 12.07 21.42 12.13C21.4 12.19 21.37 12.25 21.33 12.31C21.29 12.36 21.25 12.41 21.21 12.46C21.16 12.5 21.11 12.55 21.05 12.58C21 12.62 20.94 12.65 20.88 12.67C20.82 12.7 20.76 12.72 20.69 12.73C20.63 12.74 20.56 12.75 20.5 12.75Z"
				fill="#4F4F4F"
			/>
			<path
				d="M3.5 12.75C3.24 12.75 2.97998 12.64 2.78998 12.46C2.60998 12.27 2.5 12.01 2.5 11.75C2.5 11.49 2.60998 11.23 2.78998 11.04C3.06998 10.76 3.51 10.67 3.88 10.83C4.01 10.88 4.11002 10.95 4.21002 11.04C4.39002 11.23 4.5 11.49 4.5 11.75C4.5 12.01 4.39002 12.27 4.21002 12.46C4.02002 12.64 3.76 12.75 3.5 12.75Z"
				fill="#4F4F4F"
			/>
			<path
				d="M20.5 15.5C20.3 15.5 20.11 15.42 19.97 15.28C19.83 15.14 19.75 14.95 19.75 14.75C19.75 14.65 19.77 14.56 19.81 14.46C19.85 14.37 19.9 14.29 19.97 14.22C20.18 14.01 20.51 13.94 20.79 14.06C20.88 14.1 20.96 14.15 21.03 14.22C21.1 14.29 21.15 14.37 21.19 14.46C21.23 14.56 21.25 14.65 21.25 14.75C21.25 14.95 21.17 15.14 21.03 15.28C20.96 15.35 20.88 15.4 20.79 15.44C20.69 15.48 20.6 15.5 20.5 15.5Z"
				fill="#4F4F4F"
			/>
			<path
				d="M3.5 15.5C3.3 15.5 3.10997 15.42 2.96997 15.28C2.89997 15.21 2.84 15.13 2.81 15.04C2.77 14.95 2.75 14.85 2.75 14.75C2.75 14.65 2.77 14.56 2.81 14.46C2.84 14.37 2.89997 14.29 2.96997 14.22C3.23997 13.94 3.75003 13.94 4.03003 14.22C4.10003 14.29 4.15 14.37 4.19 14.46C4.23 14.56 4.25 14.65 4.25 14.75C4.25 14.85 4.23 14.95 4.19 15.04C4.15 15.13 4.10003 15.21 4.03003 15.28C3.89003 15.42 3.7 15.5 3.5 15.5Z"
				fill="#4F4F4F"
			/>
			<path
				d="M20.5 18C20.43 18 20.37 17.99 20.31 17.96C20.25 17.94 20.19 17.9 20.15 17.85C20.05 17.76 20 17.63 20 17.5C20 17.37 20.05 17.24 20.15 17.15C20.33 16.96 20.67 16.96 20.85 17.15C20.95 17.24 21 17.37 21 17.5C21 17.63 20.95 17.76 20.85 17.85C20.76 17.95 20.63 18 20.5 18Z"
				fill="#4F4F4F"
			/>
			<path
				d="M3.5 18C3.37 18 3.24002 17.95 3.15002 17.85C3.05002 17.76 3 17.63 3 17.5C3 17.37 3.05002 17.24 3.15002 17.15C3.33002 16.96 3.66998 16.96 3.84998 17.15C3.94998 17.24 4 17.37 4 17.5C4 17.63 3.94998 17.76 3.84998 17.85C3.75998 17.95 3.63 18 3.5 18Z"
				fill="#4F4F4F"
			/>
			<path
				d="M7.75 7C7.65 7 7.55002 6.99 7.46002 6.97C7.36002 6.95 7.26999 6.92001 7.17999 6.88001C7.07999 6.85001 6.99998 6.8 6.91998 6.75C6.82998 6.69 6.76 6.63 6.69 6.56C6.41 6.28 6.25 5.89 6.25 5.5C6.25 5.11 6.41 4.72 6.69 4.44C7.25 3.88 8.25 3.88 8.81 4.44C9.09 4.72 9.25 5.1 9.25 5.5C9.25 5.9 9.09 6.28 8.81 6.56C8.74 6.63 8.66002 6.69 8.58002 6.75C8.50002 6.8 8.41001 6.85001 8.32001 6.88001C8.23001 6.92001 8.13998 6.95 8.03998 6.97C7.94998 6.99 7.85 7 7.75 7Z"
				fill="#4F4F4F"
			/>
			<path
				d="M16.25 7C16.15 7 16.05 6.99 15.96 6.97C15.86 6.95 15.77 6.92001 15.68 6.88001C15.59 6.85001 15.5 6.8 15.42 6.75C15.34 6.69 15.26 6.63 15.19 6.56C15.12 6.49 15.06 6.41 15 6.33C14.95 6.25 14.9 6.16001 14.86 6.07001C14.83 5.98001 14.8 5.88999 14.78 5.78999C14.76 5.69999 14.75 5.6 14.75 5.5C14.75 5.11 14.91 4.72 15.19 4.44C15.26 4.37 15.34 4.31 15.42 4.25C15.5 4.2 15.59 4.15 15.68 4.12C15.77 4.08 15.86 4.05 15.96 4.03C16.15 3.99 16.35 3.99 16.54 4.03C16.64 4.05 16.73 4.08 16.82 4.12C16.91 4.15 17 4.2 17.08 4.25C17.16 4.31 17.24 4.37 17.31 4.44C17.59 4.72 17.75 5.11 17.75 5.5C17.75 5.6 17.74 5.69999 17.72 5.78999C17.7 5.88999 17.67 5.98001 17.64 6.07001C17.6 6.16001 17.55 6.25 17.5 6.33C17.44 6.41 17.38 6.49 17.31 6.56C17.24 6.63 17.16 6.69 17.08 6.75C17 6.8 16.91 6.85001 16.82 6.88001C16.73 6.92001 16.64 6.95 16.54 6.97C16.45 6.99 16.35 7 16.25 7Z"
				fill="#4F4F4F"
			/>
			<path
				d="M7.75 11.25C7.65 11.25 7.55002 11.24 7.46002 11.22C7.36002 11.2 7.26999 11.17 7.17999 11.14C7.08999 11.1 6.99998 11.05 6.91998 11C6.82998 10.94 6.76 10.88 6.69 10.81C6.41 10.53 6.25 10.14 6.25 9.75C6.25 9.35 6.41 8.97 6.69 8.69C7.25 8.13 8.25 8.13 8.81 8.69C9.09 8.97 9.25 9.35 9.25 9.75C9.25 10.15 9.09 10.53 8.81 10.81C8.53 11.09 8.14 11.25 7.75 11.25Z"
				fill="#4F4F4F"
			/>
			<path
				d="M16.25 11.25C16.15 11.25 16.05 11.24 15.96 11.22C15.86 11.2 15.77 11.17 15.68 11.13C15.59 11.1 15.5 11.05 15.42 11C15.34 10.94 15.26 10.88 15.19 10.81C15.12 10.74 15.06 10.66 15 10.58C14.95 10.5 14.9 10.41 14.86 10.32C14.83 10.23 14.8 10.14 14.78 10.04C14.76 9.94999 14.75 9.85 14.75 9.75C14.75 9.36 14.91 8.97 15.19 8.69C15.26 8.62 15.34 8.56 15.42 8.5C15.5 8.45 15.59 8.4 15.68 8.37C15.77 8.33 15.86 8.3 15.96 8.28C16.15 8.24 16.35 8.24 16.54 8.28C16.64 8.3 16.73 8.33 16.82 8.37C16.91 8.4 17 8.45 17.08 8.5C17.16 8.56 17.24 8.62 17.31 8.69C17.59 8.97 17.75 9.36 17.75 9.75C17.75 9.85 17.74 9.94999 17.72 10.04C17.7 10.14 17.67 10.23 17.64 10.32C17.6 10.41 17.55 10.5 17.5 10.58C17.44 10.66 17.38 10.74 17.31 10.81C17.24 10.88 17.16 10.94 17.08 11C17 11.05 16.91 11.1 16.82 11.13C16.73 11.17 16.64 11.2 16.54 11.22C16.45 11.24 16.35 11.25 16.25 11.25Z"
				fill="#4F4F4F"
			/>
			<path
				d="M7.75 14.75C7.62 14.75 7.49 14.72 7.37 14.67C7.24 14.62 7.12998 14.55 7.03998 14.46C6.94998 14.36 6.88002 14.25 6.83002 14.13C6.78002 14.01 6.75 13.88 6.75 13.75C6.75 13.49 6.85998 13.23 7.03998 13.04C7.40998 12.67 8.09002 12.67 8.46002 13.04C8.64002 13.23 8.75 13.49 8.75 13.75C8.75 13.88 8.71998 14.01 8.66998 14.13C8.61998 14.25 8.55002 14.36 8.46002 14.46C8.27002 14.64 8.01 14.75 7.75 14.75Z"
				fill="#4F4F4F"
			/>
			<path
				d="M16.25 14.75C15.99 14.75 15.73 14.64 15.54 14.46C15.36 14.27 15.25 14.01 15.25 13.75C15.25 13.49 15.36 13.23 15.54 13.04C15.91 12.67 16.58 12.67 16.96 13.04C17.14 13.23 17.25 13.49 17.25 13.75C17.25 13.82 17.24 13.88 17.23 13.95C17.22 14.01 17.2 14.07 17.17 14.13C17.15 14.19 17.12 14.25 17.08 14.3C17.04 14.36 17 14.41 16.96 14.46C16.77 14.64 16.51 14.75 16.25 14.75Z"
				fill="#4F4F4F"
			/>
			<path
				d="M7.75 17.75C7.55 17.75 7.35997 17.67 7.21997 17.53C7.14997 17.46 7.1 17.38 7.06 17.29C7.02 17.2 7 17.1 7 17C7 16.9 7.02 16.81 7.06 16.71C7.1 16.62 7.14997 16.54 7.21997 16.47C7.28997 16.4 7.37002 16.35 7.46002 16.31C7.64002 16.23 7.84998 16.23 8.03998 16.31C8.12998 16.35 8.21003 16.4 8.28003 16.47C8.42003 16.61 8.5 16.8 8.5 17C8.5 17.1 8.48 17.2 8.44 17.29C8.4 17.38 8.35003 17.46 8.28003 17.53C8.14003 17.67 7.95 17.75 7.75 17.75Z"
				fill="#4F4F4F"
			/>
			<path
				d="M16.25 17.75C16.15 17.75 16.05 17.73 15.96 17.69C15.87 17.65 15.79 17.6 15.72 17.53C15.65 17.46 15.59 17.38 15.56 17.29C15.52 17.2 15.5 17.1 15.5 17C15.5 16.9 15.52 16.8 15.56 16.71C15.6 16.62 15.65 16.54 15.72 16.47C15.79 16.4 15.87 16.35 15.96 16.31C16.14 16.23 16.35 16.23 16.54 16.31C16.63 16.35 16.71 16.4 16.78 16.47C16.85 16.54 16.9 16.62 16.94 16.71C16.98 16.8 17 16.9 17 17C17 17.1 16.98 17.2 16.94 17.29C16.9 17.38 16.85 17.46 16.78 17.53C16.64 17.67 16.45 17.75 16.25 17.75Z"
				fill="#4F4F4F"
			/>
			<path
				d="M7.75 20C7.62 20 7.49002 19.95 7.40002 19.85C7.30002 19.76 7.25 19.63 7.25 19.5C7.25 19.37 7.30002 19.24 7.40002 19.15C7.58002 18.96 7.91998 18.96 8.09998 19.15C8.19998 19.24 8.25 19.37 8.25 19.5C8.25 19.63 8.19998 19.76 8.09998 19.85C8.00998 19.95 7.88 20 7.75 20Z"
				fill="#4F4F4F"
			/>
			<path
				d="M16.25 20C16.18 20 16.12 19.99 16.06 19.96C16 19.94 15.94 19.9 15.9 19.85C15.85 19.81 15.81 19.75 15.79 19.69C15.76 19.63 15.75 19.57 15.75 19.5C15.75 19.37 15.8 19.24 15.9 19.15C16.08 18.96 16.42 18.96 16.6 19.15C16.7 19.24 16.75 19.37 16.75 19.5C16.75 19.57 16.74 19.63 16.71 19.69C16.69 19.75 16.65 19.81 16.6 19.85C16.51 19.95 16.38 20 16.25 20Z"
				fill="#4F4F4F"
			/>
		</svg>
	),
	Close: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth="1.5"
			stroke="currentColor"
			className="size-6"
			{...props}>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M6 18 18 6M6 6l12 12"
			/>
		</svg>
	),
	Menu: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M21 7.75H3C2.59 7.75 2.25 7.41 2.25 7C2.25 6.59 2.59 6.25 3 6.25H21C21.41 6.25 21.75 6.59 21.75 7C21.75 7.41 21.41 7.75 21 7.75Z"
				fill="#4F4F4F"
			/>
			<path
				d="M21 12.75H3C2.59 12.75 2.25 12.41 2.25 12C2.25 11.59 2.59 11.25 3 11.25H21C21.41 11.25 21.75 11.59 21.75 12C21.75 12.41 21.41 12.75 21 12.75Z"
				fill="#4F4F4F"
			/>
			<path
				d="M21 17.75H3C2.59 17.75 2.25 17.41 2.25 17C2.25 16.59 2.59 16.25 3 16.25H21C21.41 16.25 21.75 16.59 21.75 17C21.75 17.41 21.41 17.75 21 17.75Z"
				fill="#4F4F4F"
			/>
		</svg>
	),
	Notification: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="24"
			height="24"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M12.8933 9.66L12.2267 8.55333C12.0867 8.30666 11.96 7.84 11.96 7.56666V5.87999C11.96 4.31333 11.04 2.95999 9.71335 2.32666C9.36668 1.71333 8.72668 1.33333 7.99335 1.33333C7.26668 1.33333 6.61335 1.72666 6.26668 2.34666C4.96668 2.99333 4.06668 4.33333 4.06668 5.87999V7.56666C4.06668 7.84 3.94001 8.30666 3.80001 8.54666L3.12668 9.66C2.86001 10.1067 2.80001 10.6 2.96668 11.0533C3.12668 11.5 3.50668 11.8467 4.00001 12.0133C5.29335 12.4533 6.65335 12.6667 8.01335 12.6667C9.37335 12.6667 10.7333 12.4533 12.0267 12.02C12.4933 11.8667 12.8533 11.5133 13.0267 11.0533C13.2 10.5933 13.1533 10.0867 12.8933 9.66Z"
				fill="currentColor"
			/>
			<path
				d="M9.88666 13.34C9.60666 14.1133 8.86666 14.6667 7.99999 14.6667C7.47333 14.6667 6.95333 14.4533 6.58666 14.0733C6.37333 13.8733 6.21333 13.6067 6.12 13.3333C6.20666 13.3467 6.29333 13.3533 6.38666 13.3667C6.53999 13.3867 6.69999 13.4067 6.85999 13.42C7.23999 13.4533 7.62666 13.4733 8.01333 13.4733C8.39333 13.4733 8.77333 13.4533 9.14666 13.42C9.28666 13.4067 9.42666 13.4 9.55999 13.38C9.66666 13.3667 9.77333 13.3533 9.88666 13.34Z"
				fill="currentColor"
			/>
		</svg>
	),
	Maximize: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
				fill="#4F4F4F"
			/>
			<path
				d="M6 18.75C5.81 18.75 5.62 18.68 5.47 18.53C5.18 18.24 5.18 17.76 5.47 17.47L17.47 5.47C17.76 5.18 18.24 5.18 18.53 5.47C18.82 5.76 18.82 6.24 18.53 6.53L6.53 18.53C6.38 18.68 6.19 18.75 6 18.75Z"
				fill="#4F4F4F"
			/>
			<path
				d="M18 10.75C17.59 10.75 17.25 10.41 17.25 10V6.75H14C13.59 6.75 13.25 6.41 13.25 6C13.25 5.59 13.59 5.25 14 5.25H18C18.41 5.25 18.75 5.59 18.75 6V10C18.75 10.41 18.41 10.75 18 10.75Z"
				fill="#4F4F4F"
			/>
			<path
				d="M10 18.75H6C5.59 18.75 5.25 18.41 5.25 18V14C5.25 13.59 5.59 13.25 6 13.25C6.41 13.25 6.75 13.59 6.75 14V17.25H10C10.41 17.25 10.75 17.59 10.75 18C10.75 18.41 10.41 18.75 10 18.75Z"
				fill="#4F4F4F"
			/>
		</svg>
	),
	InfoCircle: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path d="M12 1.25C6.07 1.25 1.25 6.07 1.25 12C1.25 17.93 6.07 22.75 12 22.75C17.93 22.75 22.75 17.93 22.75 12C22.75 6.07 17.93 1.25 12 1.25ZM12 21.25C6.9 21.25 2.75 17.1 2.75 12C2.75 6.9 6.9 2.75 12 2.75C17.1 2.75 21.25 6.9 21.25 12C21.25 17.1 17.1 21.25 12 21.25Z" />
			<path d="M12 10.25C11.59 10.25 11.25 10.59 11.25 11V16C11.25 16.41 11.59 16.75 12 16.75C12.41 16.75 12.75 16.41 12.75 16V11C12.75 10.59 12.41 10.25 12 10.25Z" />
			<path d="M12 6.99999C11.87 6.99999 11.74 7.02999 11.62 7.07999C11.5 7.12999 11.39 7.2 11.29 7.29C11.2 7.39 11.13 7.49 11.08 7.62C11.03 7.74 11 7.87 11 8C11 8.13 11.03 8.26 11.08 8.38C11.13 8.5 11.2 8.61 11.29 8.71C11.39 8.8 11.5 8.87 11.62 8.92C11.86 9.02 12.14 9.02 12.38 8.92C12.5 8.87 12.61 8.8 12.71 8.71C12.8 8.61 12.87 8.5 12.92 8.38C12.97 8.26 13 8.13 13 8C13 7.87 12.97 7.74 12.92 7.62C12.87 7.49 12.8 7.39 12.71 7.29C12.61 7.2 12.5 7.12999 12.38 7.07999C12.26 7.02999 12.13 6.99999 12 6.99999Z" />
		</svg>
	),
	Cog: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M12 22.63C11.33 22.63 10.65 22.48 10.12 22.17L4.61999 19C2.37999 17.49 2.23999 17.26 2.23999 14.89V9.10999C2.23999 6.73999 2.36999 6.50999 4.56999 5.01999L10.11 1.81999C11.16 1.20999 12.81 1.20999 13.86 1.81999L19.38 4.99999C21.62 6.50999 21.76 6.73999 21.76 9.10999V14.88C21.76 17.25 21.63 17.48 19.43 18.97L13.89 22.17C13.35 22.48 12.67 22.63 12 22.63ZM12 2.86999C11.58 2.86999 11.17 2.94999 10.88 3.11999L5.37999 6.29999C3.74999 7.39999 3.74999 7.39999 3.74999 9.10999V14.88C3.74999 16.59 3.74999 16.59 5.41999 17.72L10.88 20.87C11.47 21.21 12.54 21.21 13.13 20.87L18.63 17.69C20.25 16.59 20.25 16.59 20.25 14.88V9.10999C20.25 7.39999 20.25 7.39999 18.58 6.26999L13.12 3.11999C12.83 2.94999 12.42 2.86999 12 2.86999Z"
				fill="#4F4F4F"
			/>
			<path
				d="M12 15.75C9.93 15.75 8.25 14.07 8.25 12C8.25 9.93 9.93 8.25 12 8.25C14.07 8.25 15.75 9.93 15.75 12C15.75 14.07 14.07 15.75 12 15.75ZM12 9.75C10.76 9.75 9.75 10.76 9.75 12C9.75 13.24 10.76 14.25 12 14.25C13.24 14.25 14.25 13.24 14.25 12C14.25 10.76 13.24 9.75 12 9.75Z"
				fill="#4F4F4F"
			/>
		</svg>
	),
	ArrorRight: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M9.52169 18.0001C9.3891 18.0001 9.2565 17.9517 9.15182 17.8479C8.94943 17.6471 8.94943 17.3149 9.15182 17.1142L13.702 12.6014C14.037 12.2692 14.037 11.7293 13.702 11.3971L9.15182 6.88433C8.94943 6.68361 8.94943 6.35138 9.15182 6.15066C9.3542 5.94994 9.68918 5.94994 9.89157 6.15066L14.4417 10.6634C14.7976 11.0164 15 11.494 15 11.9993C15 12.5045 14.8046 12.9821 14.4417 13.3351L9.89157 17.8479C9.78689 17.9448 9.65429 18.0001 9.52169 18.0001Z"
				fill="currentColor"
			/>
		</svg>
	),
	Settings: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M22 7.25H16C15.59 7.25 15.25 6.91 15.25 6.5C15.25 6.09 15.59 5.75 16 5.75H22C22.41 5.75 22.75 6.09 22.75 6.5C22.75 6.91 22.41 7.25 22 7.25Z"
				fill="#64676C"
			/>
			<path
				d="M6 7.25H2C1.59 7.25 1.25 6.91 1.25 6.5C1.25 6.09 1.59 5.75 2 5.75H6C6.41 5.75 6.75 6.09 6.75 6.5C6.75 6.91 6.41 7.25 6 7.25Z"
				fill="#64676C"
			/>
			<path
				d="M10 10.75C7.66 10.75 5.75 8.84 5.75 6.5C5.75 4.16 7.66 2.25 10 2.25C12.34 2.25 14.25 4.16 14.25 6.5C14.25 8.84 12.34 10.75 10 10.75ZM10 3.75C8.48 3.75 7.25 4.98 7.25 6.5C7.25 8.02 8.48 9.25 10 9.25C11.52 9.25 12.75 8.02 12.75 6.5C12.75 4.98 11.52 3.75 10 3.75Z"
				fill="#64676C"
			/>
			<path
				d="M22 18.25H18C17.59 18.25 17.25 17.91 17.25 17.5C17.25 17.09 17.59 16.75 18 16.75H22C22.41 16.75 22.75 17.09 22.75 17.5C22.75 17.91 22.41 18.25 22 18.25Z"
				fill="#64676C"
			/>
			<path
				d="M8 18.25H2C1.59 18.25 1.25 17.91 1.25 17.5C1.25 17.09 1.59 16.75 2 16.75H8C8.41 16.75 8.75 17.09 8.75 17.5C8.75 17.91 8.41 18.25 8 18.25Z"
				fill="#64676C"
			/>
			<path
				d="M14 21.75C11.66 21.75 9.75 19.84 9.75 17.5C9.75 15.16 11.66 13.25 14 13.25C16.34 13.25 18.25 15.16 18.25 17.5C18.25 19.84 16.34 21.75 14 21.75ZM14 14.75C12.48 14.75 11.25 15.98 11.25 17.5C11.25 19.02 12.48 20.25 14 20.25C15.52 20.25 16.75 19.02 16.75 17.5C16.75 15.98 15.52 14.75 14 14.75Z"
				fill="#64676C"
			/>
		</svg>
	),
	Search: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
				fill="#64676C"
			/>
			<path
				d="M22 22.75C21.81 22.75 21.62 22.68 21.47 22.53L19.47 20.53C19.18 20.24 19.18 19.76 19.47 19.47C19.76 19.18 20.24 19.18 20.53 19.47L22.53 21.47C22.82 21.76 22.82 22.24 22.53 22.53C22.38 22.68 22.19 22.75 22 22.75Z"
				fill="#64676C"
			/>
		</svg>
	),
	Add: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M18 12.75H6C5.59 12.75 5.25 12.41 5.25 12C5.25 11.59 5.59 11.25 6 11.25H18C18.41 11.25 18.75 11.59 18.75 12C18.75 12.41 18.41 12.75 18 12.75Z"
				fill="#007BFF"
			/>
			<path
				d="M12 18.75C11.59 18.75 11.25 18.41 11.25 18V6C11.25 5.59 11.59 5.25 12 5.25C12.41 5.25 12.75 5.59 12.75 6V18C12.75 18.41 12.41 18.75 12 18.75Z"
				fill="#007BFF"
			/>
		</svg>
	),
	DocumentUpload: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M7.5 14.7917C7.15834 14.7917 6.875 14.5083 6.875 14.1667V10.675L6.275 11.275C6.03334 11.5167 5.63334 11.5167 5.39167 11.275C5.15 11.0333 5.15 10.6333 5.39167 10.3917L7.05834 8.72501C7.23334 8.55001 7.50834 8.49168 7.74167 8.59168C7.975 8.68334 8.125 8.91668 8.125 9.16668V14.1667C8.125 14.5083 7.84167 14.7917 7.5 14.7917Z"
				fill="#4F4F4F"
			/>
			<path
				d="M9.16666 11.4583C9.00832 11.4583 8.84999 11.4 8.72499 11.275L7.05833 9.60835C6.81666 9.36668 6.81666 8.96668 7.05833 8.72501C7.29999 8.48335 7.69999 8.48335 7.94166 8.72501L9.60832 10.3917C9.84999 10.6333 9.84999 11.0333 9.60832 11.275C9.48332 11.4 9.32499 11.4583 9.16666 11.4583Z"
				fill="#4F4F4F"
			/>
			<path
				d="M12.5 18.9584H7.49999C2.97499 18.9584 1.04166 17.025 1.04166 12.5V7.50002C1.04166 2.97502 2.97499 1.04169 7.49999 1.04169H11.6667C12.0083 1.04169 12.2917 1.32502 12.2917 1.66669C12.2917 2.00835 12.0083 2.29169 11.6667 2.29169H7.49999C3.65832 2.29169 2.29166 3.65835 2.29166 7.50002V12.5C2.29166 16.3417 3.65832 17.7084 7.49999 17.7084H12.5C16.3417 17.7084 17.7083 16.3417 17.7083 12.5V8.33335C17.7083 7.99169 17.9917 7.70835 18.3333 7.70835C18.675 7.70835 18.9583 7.99169 18.9583 8.33335V12.5C18.9583 17.025 17.025 18.9584 12.5 18.9584Z"
				fill="#4F4F4F"
			/>
			<path
				d="M18.3333 8.95834H15C12.15 8.95834 11.0417 7.85001 11.0417 5.00001V1.66668C11.0417 1.41668 11.1917 1.18334 11.425 1.09168C11.6583 0.991678 11.925 1.05001 12.1083 1.22501L18.775 7.89168C18.95 8.06668 19.0083 8.34168 18.9083 8.57501C18.8083 8.80834 18.5833 8.95834 18.3333 8.95834ZM12.2917 3.17501V5.00001C12.2917 7.15001 12.85 7.70834 15 7.70834H16.825L12.2917 3.17501Z"
				fill="#4F4F4F"
			/>
		</svg>
	),
	Microphone: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M10 16.4584C6.9 16.4584 4.375 13.9334 4.375 10.8334V6.66669C4.375 3.56669 6.9 1.04169 10 1.04169C13.1 1.04169 15.625 3.56669 15.625 6.66669V10.8334C15.625 13.9334 13.1 16.4584 10 16.4584ZM10 2.29169C7.59167 2.29169 5.625 4.25002 5.625 6.66669V10.8334C5.625 13.25 7.59167 15.2084 10 15.2084C12.4083 15.2084 14.375 13.25 14.375 10.8334V6.66669C14.375 4.25002 12.4083 2.29169 10 2.29169Z"
				fill="#4F4F4F"
			/>
			<path
				d="M10 18.9584C5.51667 18.9584 1.875 15.3167 1.875 10.8334V9.16669C1.875 8.82502 2.15833 8.54169 2.5 8.54169C2.84167 8.54169 3.125 8.82502 3.125 9.16669V10.8334C3.125 14.625 6.20833 17.7084 10 17.7084C13.7917 17.7084 16.875 14.625 16.875 10.8334V9.16669C16.875 8.82502 17.1583 8.54169 17.5 8.54169C17.8417 8.54169 18.125 8.82502 18.125 9.16669V10.8334C18.125 15.3167 14.4833 18.9584 10 18.9584Z"
				fill="#4F4F4F"
			/>
			<path
				d="M12.175 6.85836C12.1083 6.85836 12.0333 6.85002 11.9583 6.82502C10.6167 6.34169 9.14167 6.34169 7.8 6.82502C7.48333 6.94169 7.125 6.77502 7.00833 6.45002C6.89167 6.12502 7.05833 5.76669 7.38333 5.65002C9 5.06669 10.775 5.06669 12.3917 5.65002C12.7167 5.76669 12.8833 6.12502 12.7667 6.45002C12.675 6.70836 12.4333 6.85836 12.175 6.85836Z"
				fill="#4F4F4F"
			/>
			<path
				d="M11.4167 9.35836C11.3667 9.35836 11.3083 9.35002 11.25 9.33336C10.3583 9.09169 9.41668 9.09169 8.52501 9.33336C8.18335 9.42502 7.85002 9.22502 7.75835 8.89169C7.66668 8.55836 7.86668 8.21669 8.20002 8.12502C9.30835 7.82502 10.475 7.82502 11.5833 8.12502C11.9167 8.21669 12.1167 8.55836 12.025 8.89169C11.9417 9.18336 11.6917 9.35836 11.4167 9.35836Z"
				fill="#4F4F4F"
			/>
		</svg>
	),
	Camera: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M14.3667 18.9584H5.63334C3.3 18.9584 1.81667 17.5667 1.68334 15.2417L1.25 8.36669C1.18334 7.32502 1.54167 6.32502 2.25834 5.56669C2.96667 4.80835 3.96667 4.37502 5 4.37502C5.26667 4.37502 5.525 4.21669 5.65 3.96669L6.25 2.77502C6.74167 1.80002 7.975 1.04169 9.05 1.04169H10.9583C12.0333 1.04169 13.2583 1.80002 13.75 2.76669L14.35 3.98335C14.475 4.21669 14.725 4.37502 15 4.37502C16.0333 4.37502 17.0333 4.80835 17.7417 5.56669C18.4583 6.33335 18.8167 7.32502 18.75 8.36669L18.3167 15.25C18.1667 17.6084 16.725 18.9584 14.3667 18.9584ZM9.05 2.29169C8.43334 2.29169 7.65 2.77502 7.36667 3.33335L6.76667 4.53335C6.41667 5.20835 5.74167 5.62502 5 5.62502C4.3 5.62502 3.65 5.90835 3.16667 6.41669C2.69167 6.92502 2.45 7.59169 2.5 8.28335L2.93334 15.1667C3.03334 16.85 3.94167 17.7084 5.63334 17.7084H14.3667C16.05 17.7084 16.9583 16.85 17.0667 15.1667L17.5 8.28335C17.5417 7.59169 17.3083 6.92502 16.8333 6.41669C16.35 5.90835 15.7 5.62502 15 5.62502C14.2583 5.62502 13.5833 5.20835 13.2333 4.55002L12.625 3.33335C12.35 2.78335 11.5667 2.30002 10.95 2.30002H9.05V2.29169Z"
				fill="#4F4F4F"
			/>
			<path
				d="M11.25 7.29169H8.75C8.40833 7.29169 8.125 7.00835 8.125 6.66669C8.125 6.32502 8.40833 6.04169 8.75 6.04169H11.25C11.5917 6.04169 11.875 6.32502 11.875 6.66669C11.875 7.00835 11.5917 7.29169 11.25 7.29169Z"
				fill="#4F4F4F"
			/>
			<path
				d="M9.99999 15.625C8.15832 15.625 6.66666 14.1333 6.66666 12.2916C6.66666 10.45 8.15832 8.95831 9.99999 8.95831C11.8417 8.95831 13.3333 10.45 13.3333 12.2916C13.3333 14.1333 11.8417 15.625 9.99999 15.625ZM9.99999 10.2083C8.84999 10.2083 7.91666 11.1416 7.91666 12.2916C7.91666 13.4416 8.84999 14.375 9.99999 14.375C11.15 14.375 12.0833 13.4416 12.0833 12.2916C12.0833 11.1416 11.15 10.2083 9.99999 10.2083Z"
				fill="#4F4F4F"
			/>
		</svg>
	),
	Send: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M14.22 21.63C13.04 21.63 11.37 20.8 10.05 16.83L9.33 14.67L7.17 13.95C3.21 12.63 2.38 10.96 2.38 9.78001C2.38 8.61001 3.21 6.93001 7.17 5.60001L15.66 2.77001C17.78 2.06001 19.55 2.27001 20.64 3.35001C21.73 4.43001 21.94 6.21001 21.23 8.33001L18.4 16.82C17.07 20.8 15.4 21.63 14.22 21.63ZM7.64 7.03001C4.86 7.96001 3.87 9.06001 3.87 9.78001C3.87 10.5 4.86 11.6 7.64 12.52L10.16 13.36C10.38 13.43 10.56 13.61 10.63 13.83L11.47 16.35C12.39 19.13 13.5 20.12 14.22 20.12C14.94 20.12 16.04 19.13 16.97 16.35L19.8 7.86001C20.31 6.32001 20.22 5.06001 19.57 4.41001C18.92 3.76001 17.66 3.68001 16.13 4.19001L7.64 7.03001Z"
				fill="#4F4F4F"
			/>
			<path
				d="M10.11 14.4C9.92005 14.4 9.73005 14.33 9.58005 14.18C9.29005 13.89 9.29005 13.41 9.58005 13.12L13.16 9.53C13.45 9.24 13.93 9.24 14.22 9.53C14.51 9.82 14.51 10.3 14.22 10.59L10.64 14.18C10.5 14.33 10.3 14.4 10.11 14.4Z"
				fill="#4F4F4F"
			/>
		</svg>
	),
	Paperclip: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M10.97 22.75C7.24997 22.75 4.21997 19.72 4.21997 16V10C4.21997 5.73 7.69997 2.25 11.97 2.25C16.24 2.25 19.72 5.73 19.72 10V15.5C19.72 17.84 17.81 19.75 15.47 19.75C13.13 19.75 11.22 17.84 11.22 15.5V12C11.22 11.59 11.56 11.25 11.97 11.25C12.38 11.25 12.72 11.59 12.72 12V15.5C12.72 17.02 13.95 18.25 15.47 18.25C16.99 18.25 18.22 17.02 18.22 15.5V10C18.22 6.55 15.42 3.75 11.97 3.75C8.51997 3.75 5.71997 6.55 5.71997 10V16C5.71997 18.89 8.06997 21.25 10.97 21.25C11.38 21.25 11.72 21.59 11.72 22C11.72 22.41 11.39 22.75 10.97 22.75Z"
				fill="#4F4F4F"
			/>
		</svg>
	),
	Facebook: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="29"
			height="28"
			viewBox="0 0 29 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<g clipPath="url(#clip0_0_866)">
				<path
					d="M28.5 14C28.5 6.26801 22.232 0 14.5 0C6.76801 0 0.5 6.26801 0.5 14C0.5 20.9877 5.61957 26.7796 12.3125 27.8299V18.0469H8.75781V14H12.3125V10.9156C12.3125 7.40687 14.4027 5.46875 17.6005 5.46875C19.1318 5.46875 20.7344 5.74219 20.7344 5.74219V9.1875H18.9691C17.23 9.1875 16.6875 10.2668 16.6875 11.375V14H20.5703L19.9496 18.0469H16.6875V27.8299C23.3804 26.7796 28.5 20.9877 28.5 14Z"
					fill="#1877F2"
				/>
				<path
					d="M19.9496 18.0469L20.5703 14H16.6875V11.375C16.6875 10.2679 17.23 9.1875 18.9691 9.1875H20.7344V5.74219C20.7344 5.74219 19.1323 5.46875 17.6005 5.46875C14.4027 5.46875 12.3125 7.40688 12.3125 10.9156V14H8.75781V18.0469H12.3125V27.8299C13.762 28.0567 15.238 28.0567 16.6875 27.8299V18.0469H19.9496Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_0_866">
					<rect
						width="28"
						height="28"
						fill="white"
						transform="translate(0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	),
	Google: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="29"
			height="28"
			viewBox="0 0 29 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<g clipPath="url(#clip0_0_851)">
				<path
					d="M28.2273 14.3223C28.2273 13.3706 28.1501 12.4138 27.9855 11.4775H14.7803V16.8687H22.3423C22.0285 18.6074 21.0202 20.1456 19.5438 21.123V24.621H24.0553C26.7046 22.1827 28.2273 18.5817 28.2273 14.3223Z"
					fill="#4285F4"
				/>
				<path
					d="M14.7803 28.0009C18.5561 28.0009 21.7404 26.7611 24.0605 24.6211L19.549 21.1231C18.2938 21.977 16.6734 22.4606 14.7854 22.4606C11.133 22.4606 8.0362 19.9965 6.92505 16.6836H2.26953V20.2897C4.64616 25.0172 9.48688 28.0009 14.7803 28.0009Z"
					fill="#34A853"
				/>
				<path
					d="M6.9199 16.6837C6.33346 14.9449 6.33346 13.0621 6.9199 11.3234V7.71729H2.26953C0.283856 11.6732 0.283856 16.3339 2.26953 20.2898L6.9199 16.6837Z"
					fill="#FBBC04"
				/>
				<path
					d="M14.7803 5.54127C16.7762 5.51041 18.7053 6.26146 20.1508 7.64012L24.1479 3.64305C21.6169 1.26642 18.2578 -0.0402103 14.7803 0.000943444C9.48687 0.000943444 4.64616 2.98459 2.26953 7.71728L6.91991 11.3234C8.02591 8.00536 11.1279 5.54127 14.7803 5.54127Z"
					fill="#EA4335"
				/>
			</g>
			<defs>
				<clipPath id="clip0_0_851">
					<rect
						width="28"
						height="28"
						fill="white"
						transform="translate(0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	),
	Apple: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			width="29"
			height="28"
			viewBox="0 0 29 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22.7833 22.3534C23.4717 21.3034 23.7284 20.7667 24.2534 19.5884C20.38 18.1184 19.7617 12.6 23.5884 10.4883C22.4217 9.01834 20.7767 8.16669 19.225 8.16669C18.105 8.16669 17.335 8.45837 16.6467 8.7267C16.0633 8.94837 15.5384 9.14668 14.885 9.14668C14.185 9.14668 13.5667 8.92503 12.9133 8.6917C12.2017 8.43503 11.455 8.16669 10.5217 8.16669C8.78335 8.16669 6.92835 9.22836 5.75002 11.0484C4.09335 13.615 4.38502 18.4217 7.05669 22.5284C8.01335 23.9984 9.29668 25.6434 10.965 25.6667C11.665 25.6784 12.12 25.4684 12.6217 25.2467C13.1933 24.99 13.8117 24.71 14.8967 24.71C15.9817 24.6983 16.5883 24.99 17.16 25.2467C17.65 25.4684 18.0933 25.6784 18.7817 25.6667C20.4733 25.6434 21.8267 23.8234 22.7833 22.3534Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.98 2.33331C19.1667 3.61665 18.6417 4.88833 17.9533 5.77499C17.2183 6.73166 15.935 7.47831 14.6983 7.43164C14.4767 6.19497 15.0484 4.9233 15.7484 4.07164C16.53 3.1383 17.8483 2.41498 18.98 2.33331Z"
				fill="black"
			/>
		</svg>
	),
	Visibility: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			className="text-[#64676C]"
			width="18"
			height="16"
			viewBox="0 0 18 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.30191 13.2323C3.41857 13.3323 3.56858 13.3823 3.71024 13.3823C3.88524 13.3823 4.06025 13.3073 4.17691 13.1657C4.4019 12.899 4.36858 12.5073 4.11025 12.2823C3.31858 11.599 2.6019 10.7573 1.9769 9.78234C1.3519 8.80734 1.3519 7.10734 1.9769 6.13234C3.7769 3.30734 6.3519 1.69068 9.04358 1.69068C10.6019 1.69068 12.1519 2.23234 13.5186 3.26567C13.7936 3.47401 14.1853 3.41567 14.3936 3.14067C14.6019 2.86567 14.5436 2.47401 14.2686 2.26568C12.6853 1.07401 10.8769 0.440674 9.04358 0.440674C5.91857 0.440674 2.96024 2.27401 0.926914 5.45734C0.0435677 6.84067 0.0435677 9.08234 0.926914 10.4573C1.61025 11.5407 2.41024 12.474 3.30191 13.2323ZM5.81025 14.8073C6.8519 15.249 7.93525 15.474 9.04358 15.474C12.1686 15.474 15.1269 13.6407 17.1603 10.4573C18.0436 9.07401 18.0436 6.83234 17.1603 5.45734C16.8769 5.00734 16.5603 4.57401 16.2353 4.17401C16.0103 3.89901 15.6186 3.86567 15.3519 4.08234C15.0853 4.30734 15.0436 4.69901 15.2603 4.96567C15.5603 5.33234 15.8436 5.72401 16.1019 6.13234C16.7269 7.10734 16.7269 8.80734 16.1019 9.78234C14.3019 12.6073 11.7269 14.224 9.03524 14.224C8.1019 14.224 7.17691 14.0323 6.29358 13.6573C5.9769 13.524 5.61025 13.674 5.4769 13.9907C5.34357 14.3073 5.49358 14.674 5.81025 14.8073ZM12.6466 7.9574C12.6466 5.9675 11.0335 4.35437 9.04358 4.35437C7.05368 4.35437 5.44056 5.9675 5.44056 7.9574C5.44056 9.9473 7.05368 11.5604 9.04358 11.5604C11.0335 11.5604 12.6466 9.9473 12.6466 7.9574ZM11.474 7.9574C11.474 6.61512 10.3859 5.52698 9.04358 5.52698C7.7013 5.52698 6.61316 6.61512 6.61316 7.9574C6.61316 9.29968 7.7013 10.3878 9.04358 10.3878C10.3859 10.3878 11.474 9.29968 11.474 7.9574Z"
				fill="currentColor"
			/>
		</svg>
	),
	VisibilityOff: (props: React.SVGProps<SVGSVGElement>) => (
		<svg
			className="text-[#64676C]"
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M8.95625 16.4729C7.84792 16.4729 6.76458 16.2479 5.72292 15.8062C5.40625 15.6729 5.25625 15.3062 5.38958 14.9896C5.52292 14.6729 5.88958 14.5229 6.20625 14.6562C7.08958 15.0312 8.01458 15.2229 8.94792 15.2229C11.6396 15.2229 14.2146 13.6062 16.0146 10.7812C16.6396 9.80625 16.6396 8.10625 16.0146 7.13125C15.7562 6.72292 15.4729 6.33125 15.1729 5.96458C14.9562 5.69792 14.9979 5.30625 15.2646 5.08125C15.5312 4.86458 15.9229 4.89792 16.1479 5.17292C16.4729 5.57292 16.7896 6.00625 17.0729 6.45625C17.9562 7.83125 17.9562 10.0729 17.0729 11.4562C15.0396 14.6396 12.0812 16.4729 8.95625 16.4729Z"
				fill="currentColor"
			/>
			<path
				d="M9.53125 12.5146C9.23958 12.5146 8.97292 12.3062 8.91458 12.0062C8.84792 11.6646 9.07292 11.3396 9.41458 11.2812C10.3313 11.1146 11.0979 10.3479 11.2646 9.43125C11.3313 9.08958 11.6562 8.87292 11.9979 8.93125C12.3396 8.99792 12.5646 9.32292 12.4979 9.66458C12.2313 11.1062 11.0813 12.2479 9.64792 12.5146C9.60625 12.5062 9.57292 12.5146 9.53125 12.5146Z"
				fill="currentColor"
			/>
			<path
				d="M6.84792 11.6896C6.68958 11.6896 6.53125 11.6312 6.40625 11.5062C5.72292 10.8229 5.34792 9.91458 5.34792 8.95625C5.34792 6.96458 6.96458 5.34792 8.95625 5.34792C9.91458 5.34792 10.8229 5.72292 11.5062 6.40625C11.6229 6.52292 11.6896 6.68125 11.6896 6.84792C11.6896 7.01458 11.6229 7.17292 11.5062 7.28958L7.28958 11.5062C7.16458 11.6312 7.00625 11.6896 6.84792 11.6896ZM8.95625 6.59792C7.65625 6.59792 6.59792 7.65625 6.59792 8.95625C6.59792 9.37292 6.70625 9.77291 6.90625 10.1229L10.1229 6.90625C9.77292 6.70625 9.37292 6.59792 8.95625 6.59792Z"
				fill="currentColor"
			/>
			<path
				d="M3.62292 14.3812C3.48125 14.3812 3.33125 14.3312 3.21458 14.2312C2.32292 13.4729 1.52292 12.5396 0.839583 11.4562C-0.04375 10.0812 -0.04375 7.83958 0.839583 6.45625C2.87292 3.27292 5.83125 1.43958 8.95625 1.43958C10.7896 1.43958 12.5979 2.07292 14.1812 3.26458C14.4562 3.47292 14.5146 3.86458 14.3062 4.13958C14.0979 4.41458 13.7062 4.47292 13.4312 4.26458C12.0646 3.23125 10.5146 2.68958 8.95625 2.68958C6.26458 2.68958 3.68958 4.30625 1.88958 7.13125C1.26458 8.10625 1.26458 9.80625 1.88958 10.7812C2.51458 11.7562 3.23125 12.5979 4.02292 13.2812C4.28125 13.5062 4.31458 13.8979 4.08958 14.1646C3.97292 14.3062 3.79792 14.3812 3.62292 14.3812Z"
				fill="currentColor"
			/>
			<path
				d="M0.622917 17.9146C0.464583 17.9146 0.30625 17.8563 0.18125 17.7313C-0.0604167 17.4896 -0.0604167 17.0896 0.18125 16.8479L6.40625 10.6229C6.64792 10.3813 7.04792 10.3813 7.28958 10.6229C7.53125 10.8646 7.53125 11.2646 7.28958 11.5062L1.06458 17.7313C0.939583 17.8563 0.78125 17.9146 0.622917 17.9146Z"
				fill="currentColor"
			/>
			<path
				d="M11.0646 7.47292C10.9063 7.47292 10.7479 7.41458 10.6229 7.28958C10.3813 7.04792 10.3813 6.64792 10.6229 6.40625L16.8479 0.18125C17.0896 -0.0604167 17.4896 -0.0604167 17.7313 0.18125C17.9729 0.422917 17.9729 0.822917 17.7313 1.06458L11.5062 7.28958C11.3812 7.41458 11.2229 7.47292 11.0646 7.47292Z"
				fill="currentColor"
			/>
		</svg>
	),
};

"use client";
import { ChatResponse } from "./ChatResponse";
import { ChatQuestion } from "./ChatQuestion";
import { useMessages } from "@/context/Messages";
import { useEffect } from "react";
import Link from "next/link";

export const ChatMessages: React.FC = () => {
	const { messages } = useMessages();

	useEffect(() => {
		const chatCtr = document.getElementById("chat-container");
		chatCtr?.scrollTo({ top: chatCtr.scrollHeight, behavior: "smooth" });
	}, [messages]);

	return (
		<div
			id="chat-container"
			className="flex flex-col gap-2 p-5 w-full flex-grow overflow-auto">
			<span className="text-sm text-gray-500 text-center italic">
				How may i help you today? <br /> For details you can ask me what i can do, {" "}
				or review the{" "}
				<Link
					href={"https://make.lumnar.tech/app_documentation"}
					className="text-primary-100 hover:underline">
					docs
				</Link>
			</span>
			{messages.map((message, i) => {
				if (message.user_message || message.pictures) {
					return (
						<ChatQuestion
							pending={!message.agent_status}
							key={message.user_message + i.toString()}
							text={message.user_message || ""}
							images={message.pictures || []}
							time={new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
						/>
					);
				}
				if (message.agent_message?.message) {
					return (
						<ChatResponse
							pending={!message.agent_status}
							key={message.agent_message + i.toString()}
							text={message.agent_message.message}
							options={message.agent_message.options}
							time={new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
						/>
					);
				}
			})}
		</div>
	);
};

"use client";
export const chatHistoryData = [
	{
		title: "Today",
		items: [
			{ label: "AI and Human Collaboration", id: 1 },
			{ label: "The Future of AI Ethics", id: 2 },
			{ label: "AI for Social Good", id: 3 },
			{ label: "AI in Healthcare", id: 4 },
		],
	},
	{
		title: "Yesterday",
		items: [
			{ label: "Machine Learning", id: 5 },
			{ label: "Deep Learning", id: 6 },
			{ label: "Natural Language Processing (NLP)", id: 7 },
			{ label: "Computer Vision", id: 8 },
			{ label: "Reinforcement Learning", id: 9 },
			{ label: "Generative AI", id: 10 },
			{ label: "AI Hardware and Chips", id: 11 },
			{ label: "AI Frameworks and Libraries", id: 12 },
		],
	},
	{
		title: "Last week",
		items: [
			{ label: "AI Consciousness", id: 13 },
			{ label: "AI Sentience", id: 14 },
			{ label: "AI and Free Will", id: 15 },
			{ label: "AI and Existential Risk", id: 16 },
			{ label: "AI and Human Identity", id: 17 },
		],
	},
];

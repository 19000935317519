"use client";
export const WelcomeMessage = () => {
	return (
		<div className="my-auto mx-5 border border-gray-300 rounded-xl bg-white shadow-lg max-w-[547px] px-6 py-4 text-lg shrink">
			<p className="max-w-[468px] leading-relaxed text-gray-700">
				<em className="text-sky-500 font-semibold">“AI Won&apos;t Replace Humans — But Humans With AI Will Replace Humans Without AI.”</em>
				<br className="my-2" />
				<span className="block mt-3">This is a natural language interface to interact with a collection AI powered automations and assistants, custom-built for each user to enhance their life and productivity. Contact us for details.</span>
			</p>
		</div>
	);
};
